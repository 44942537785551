<template>
  <viewcard--c
    title="Vincular Usuários"
    subtitle="Arraste os usuários para adicionar ou remover."
    :btsave="btedit"
    :btback="{}"
    :busy="loading"
    @clicked-save="save"
  >
    <hr class="p-0 m-0 mb-1" />
    <b-row class="mt-2">
      <b-col md="6">
        <Draggable
          v-if="ready1"
          title="Usuários Vinculados"
          icon="ArrowDownCircleIcon"
          :perPage="7"
          :list="record.users"
          :height="draggableHeight"
          @change="recordChange"
          @greatestHeight="getDraggableHeight"
        />
      </b-col>
      <b-col md="6">
        <Draggable
          v-if="ready1"
          title="Usuários Disponíveis"
          icon="ArrowDownCircleIcon"
          :searchAction="true"
          :perPage="7"
          :list="users"
          :height="draggableHeight"
          @greatestHeight="getDraggableHeight"
          @findlist="filterAvails"
          @change="UsersChange"
        />
      </b-col>
    </b-row>
  </viewcard--c>
</template>
<script>
import _usersService from "@/services/users-service";
import _groupPermissionService from "@/services/group-permissions";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required, email } from "@validations";
import Draggable from "@/components/Draggable.vue";
import { BListGroupItem } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    Draggable,
  },
  data() {
    return {
      btedit: { permission: `permission.permissiongroup.edit` },
      loading: false,
      users: [],
      usersAux: [],
      searchIncludes: null,
      searchAvail: null,
      record: {
        id: 0,
        name: "",
        users: [],
        usersAux: [],
      },
      ready1: false,
      ready2: false,
      draggableHeight: 0
    };
  },
  created() {
    localize("pt_BR", pt_br);
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    getDraggableHeight(_height) {
      if (_height > this.draggableHeight) {
        this.draggableHeight = _height;
      }
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _groupPermissionService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.record.users = res.content.userGroupPermissions.map((m) => {
              return {
                label: m.user.name,
                subtitle: m.user.email,
                value: m.user.id,
              };
            });
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {
            this.loading = false
            this.ready1 = true
          });
      }
    },
    save() {
      this.record.userGroupPermissions = this.record.users.map((m) => {
        return {
          user_id: m.value,
        };
      });
      const payload = { data: { ...this.record } };
      const _createOrUpdate =
        this.record.id <= 0
          ? _groupPermissionService.create(payload)
          : _groupPermissionService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Usuários vinculados com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    filterincludes() {
      if (this.searchIncludes) {
        this.record.permissions = this.record.permissionsAux.filter((f) =>
          f.label.toLowerCase().includes(this.searchIncludes.toLowerCase())
        );
      } else {
        this.record.permissions = this.record.permissionsAux;
      }
    },
    filterAvails(_searchTxt) {
      this.loading = true;
      _usersService
        .show(1, _searchTxt)
        .then((res) => {
          if (res.content) {
            this.users = res.content.map((m) => {
              return {
                label: m.name,
                subtitle: m.email,
                value: m.id,
              };
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    recordChange(payload) {
      this.record.users = payload;
    },
    UsersChange(payload) {
      this.users = payload;
    },
  },
};
</script>